<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Use the TLC plates to answer the question.</p>

      <p class="mb-3 pl-6">
        <v-img
          style="max-width: 413px"
          src="/img/assignments/UCIrvine/standardsExtractTLCPlate.png"
        />
      </p>

      <p class="mb-2">a) Rank the compounds A-C from least polar down to most polar.</p>

      <drag-to-rank-input
        v-model="inputs.input1"
        :items="rankOptions"
        class="mb-7"
        style="max-width: 512px"
        prepend-text="Least polar"
        append-text="Most polar"
      />

      <p class="mb-n3">b) Why didn't C move from the baseline?</p>

      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="ml-6 mb-0">
        <v-radio
          v-for="option in options1"
          :key="'pt-2-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        c) <b>Select all that apply.</b>The compounds in the extract (essential oil unknown) are:
      </p>

      <p v-for="option in options2" :key="'pt-2-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input3" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '../inputs/DragToRankInput.vue';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LDQ2S2Q3',
  components: {
    StembleLatex,
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
        input2: null,
        input3: [],
      },
      rankOptions: ['A', 'B', 'C'],
      options1: [
        {text: 'C is strongly adsorbed to the silica because of its polarity.', value: 'adsorbed'},
        {text: 'C is not soluble in the eluent.', value: 'notSoluble'},
        {text: 'C was not dissolved when spotted on the plate.', value: 'notDissolved'},
        {text: 'C is nonpolar, like the silica.', value: 'nonpolar'},
      ],
      options2: [
        {text: 'A', value: 'a'},
        {text: 'B', value: 'b'},
        {text: 'C', value: 'c'},
      ],
    };
  },
};
</script>
<style scoped></style>
